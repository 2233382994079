import React, { Component } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';

import Pager from '../shared/Pager';
import InvoicesTableActions from './actions';
import LoadingDataTable from '../shared/LoadingDataTable';
import { Header, Body, Row, Cell } from '../shared/DataTable';
import Sorter from '../shared/Sorter';
import styles, { css } from './styles';
import InvoiceRow from './components/InvoiceRow';

class InvoicesTable extends Component {
  linkToInvoice = (invoicePath) => window.location.assign(invoicePath);

  render() {
    const baseUrl = 'invoices.json';
    const emptyRow = (
      <Row isHidden>
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <Cell />
      </Row>
    );

    return (
      <React.Fragment>
        <InvoicesTableActions baseUrl={baseUrl} initialData={this.props}>
          {actions => (
            <div>
              <LoadingDataTable isLoading={actions.isLoading}>
                <Header>
                  <Row>
                    <Cell isHeader>
                      <Sorter
                        isSorted={actions.sortKey === 'number'}
                        onSort={actions.sort}
                        sortDirection={actions.sortBy.number}
                        sortKey="number"
                      >
                        Number
                      </Sorter>
                    </Cell>
                    <Cell isHeader>
                      <Sorter
                        isSorted={actions.sortKey === 'issue_date'}
                        onSort={actions.sort}
                        sortDirection={actions.sortBy.issue_date}
                        sortKey="issue_date"
                      >
                        Issued At
                      </Sorter>
                    </Cell>
                    <Cell isHeader>
                      <Sorter
                        isSorted={actions.sortKey === 'due_date'}
                        onSort={actions.sort}
                        sortDirection={actions.sortBy.due_date}
                        sortKey="due_date"
                      >
                        Due Date
                      </Sorter>
                    </Cell>
                    <Cell isHeader>Status</Cell>
                    <Cell isHeader alignRight>
                      Total Amount
                    </Cell>
                    <Cell isHeader alignRight>
                      Amount Due
                    </Cell>
                    <Cell isHeader alignRight />
                  </Row>
                </Header>

                <Body>
                  {actions.invoices.length > 0
                    ? actions.invoices.map(invoice => (
                      <InvoiceRow
                        key={invoice.uid}
                        invoice={invoice}
                        onClick={this.linkToInvoice}
                      />
                    ))
                    : emptyRow}
                </Body>
              </LoadingDataTable>

              <div className={css(styles.paddedPager)}>
                <Pager
                  currentPage={actions.currentPage}
                  totalEntries={actions.totalEntries}
                  pageLimit={actions.perPage}
                  onClick={actions.changePage}
                />
              </div>

              {this.props.showInvoiceLegend && <>
                <div className="invoice-legend">
                  <dl>
                    <dt>
                      <i className="fal fa-file" /> =
                    </dt>
                    <dd>Normal Invoice</dd>
                    <dt>
                      <i className="fal fa-file-plus" /> =
                    </dt>
                    <dd>Consolidated Invoice</dd>
                    <dt>
                      <i className="fal fa-file-minus" /> =
                    </dt>
                    <dd>Consolidated Invoice Segment</dd>
                  </dl>
                </div>
              </>}
            </div>
          )}
        </InvoicesTableActions>
      </React.Fragment>
    );
  }
}

InvoicesTable.propTypes = {
  // Required props
  filterableStatuses: PropTypes.array.isRequired,
};

export default InvoicesTable;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-invoices-table');

  if(node) {
    const data = JSON.parse(node.getAttribute('data-camelized-props'));

    ReactDOM.render(
      <InvoicesTable {...data} />,
      node.appendChild(document.createElement('div')),
    )
  }
});