import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

const WithTooltip = ({ children, message, addTooltip, placement, size, ...rest }) =>
  (addTooltip && message ? (
    <OverlayTrigger
      placement={placement}
      size={size}
      {...rest}
      overlay={
        <Tooltip size={size} placement={placement}>
          {message}
        </Tooltip>
      }
    >
      <span className="overlay-trigger" style={{ cursor: 'default' }}>
        {children}
      </span>
    </OverlayTrigger>
  ) : (
    children
  ));

WithTooltip.defaultProps = {
  disabled: false,
  usageIsEnabled: false,
  placement: 'right',
  size: 'auto',
};

WithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  addTooltip: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  size: PropTypes.oneOf(['auto', 'sm', 'md', 'lg']),
};

export default WithTooltip;