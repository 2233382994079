import { StyleSheet, css } from 'aphrodite';

export { css };
export default StyleSheet.create({
  paddedPager: {
    padding: '0 20px 5px 20px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
});

