import React from 'react';
import PropTypes from 'prop-types';
import DisplayReasonCodes from './DisplayReasonCodes';

const ReasonCodes = ({ reasonCodesEmpty }) =>
  (reasonCodesEmpty ? null : <DisplayReasonCodes />);

ReasonCodes.propTypes = {
  reasonCodesEmpty: PropTypes.bool.isRequired,
};

export default ReasonCodes;