import { Component } from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';
import { api } from '../utils/api';
import { buildUrlParams } from '../utils';
import { capitalizeFirst } from '../utils/helpers';
import { areBothValid } from './dateUtils';

const normalizeParams = args => ({
  direction: args.sortBy[args.sortKey],
  page: args.page,
  perPage: args.perPage,
  sortBy: args.sortKey,
  status: args.statusFilterValue,
  ...(args.appliedNumber && { number: args.appliedNumber }),
  ...(args.appliedStartDate && { startDate: args.appliedStartDate }),
  ...(args.appliedEndDate && { endDate: args.appliedEndDate }),
  ...(args.appliedCustomer && { customer: args.appliedCustomer }),
  ...(args.subscriptionId && { subscriptionId: args.subscriptionId }),
  ...(args.subscriptionGroupId && { subscriptionGroupId: args.subscriptionGroupId }),
});

const setStatusFilterValue = (initialData) => {
  if (initialData.filterableStatuses.includes(initialData.statusFilterValue)) {
    return capitalizeFirst(initialData.statusFilterValue);
  }
  return 'All Statuses';
};

class InvoicesTableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...camelizeKeys(props.initialData),
      baseUrl: props.baseUrl,
      error: '',
      isLoading: false,
      sortBy: {
        number: 'desc',
      },
      sortKey: 'number',
      statusFilterValue: setStatusFilterValue(props.initialData),
      numberFilterValue: '',
      startDateFilterValue: '',
      endDateFilterValue: '',
      customerFilterValue: '',
    };
  }

  setStatusFilter = value => this.setState({ statusFilterValue: value });
  setNumberFilter = value => this.setState({ numberFilterValue: value });
  setCustomerFilter = value => this.setState({ customerFilterValue: value });
  setStartDateFilter = value => this.setState({ startDateFilterValue: value });
  setEndDateFilter = value => this.setState({ endDateFilterValue: value });

  removeNumberFilter = () => {
    this.setState(
      {
        numberFilterValue: '',
        appliedNumber: '',
      },
      this.reload,
    );
  };

  removeStatusFilter = () => {
    this.setState(
      {
        statusFilterValue: 'All Statuses',
        appliedStatus: 'All Statuses',
      },
      this.reload,
    );
  };

  removeDatesFilter = () => {
    this.setState(
      {
        startDateFilterValue: '',
        appliedStartDate: '',
        endDateFilterValue: '',
        appliedEndDate: '',
      },
      this.reload,
    );
  };

  removeCustomerFilter = () => {
    this.setState(
      {
        customerFilterValue: '',
        appliedCustomer: '',
      },
      this.reload,
    );
  };

  removeAllFilters = () => {
    this.setState(
      {
        numberFilterValue: '',
        appliedNumber: '',
        statusFilterValue: 'All Statuses',
        appliedStatus: 'All Statuses',
        startDateFilterValue: '',
        appliedStartDate: '',
        endDateFilterValue: '',
        appliedEndDate: '',
        customerFilterValue: '',
        appliedCustomer: '',
      },
      this.reload,
    );
  };

  validateDates = () => {
    this.setState((prevState) => {
      const startDate = prevState.startDateFilterValue;
      const endDate = prevState.endDateFilterValue;
      const valid = areBothValid(startDate, endDate);

      return {
        startDateFilterValue: valid ? startDate : '',
        endDateFilterValue: valid ? endDate : '',
      };
    });
  };

  applyFilters = () => {
    this.validateDates();

    this.setState(
      {
        appliedStatus: capitalizeFirst(this.state.statusFilterValue),
        appliedNumber: this.state.numberFilterValue,
        appliedStartDate: this.state.startDateFilterValue,
        appliedEndDate: this.state.endDateFilterValue,
        appliedCustomer: this.state.customerFilterValue,
      },
      this.reload,
    );
  };

  changePage = (page) => {
    this.setState({ page }, this.reload);
  };

  sort = (sortKey) => {
    const { sortBy } = this.state;
    const direction = sortBy[sortKey] === 'desc' ? 'asc' : 'desc';
    this.setState({ sortKey, sortBy: { ...sortBy, [sortKey]: direction } }, this.reload);
  };

  reload = () => {
    const urlParams = buildUrlParams(normalizeParams(this.state));

    this.setState({ isLoading: true }, () =>
      api()
        .get(`${this.state.baseUrl}?${urlParams}`)
        .then(res => this.setState({ ...camelizeKeys(res.data) }))
        .catch(error => this.setState({ error: error.response.data.errors[0] }))
        .then(() => this.setState({ isLoading: false })),
    );
  };

  render() {
    return this.props.children({
      changePage: this.changePage,
      isLoading: this.state.isLoading,
      setStatusFilter: this.setStatusFilter,
      setNumberFilter: this.setNumberFilter,
      setStartDateFilter: this.setStartDateFilter,
      setEndDateFilter: this.setEndDateFilter,
      setCustomerFilter: this.setCustomerFilter,
      removeNumberFilter: this.removeNumberFilter,
      removeStatusFilter: this.removeStatusFilter,
      removeDatesFilter: this.removeDatesFilter,
      removeCustomerFilter: this.removeCustomerFilter,
      removeAllFilters: this.removeAllFilters,
      reload: this.reload,
      applyFilters: this.applyFilters,
      sort: this.sort,
      status: this.state.appliedStatus,
      number: this.state.appliedNumber,
      startDate: this.state.appliedStartDate,
      endDate: this.state.appliedEndDate,
      customer: this.state.appliedCustomer,
      ...this.state,
    });
  }
}

InvoicesTableActions.propTypes = {
  baseUrl: PropTypes.string,
  children: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

export default InvoicesTableActions;
