import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles, { css } from './styles';

class Sorter extends Component {
  handleClick = () => this.props.onSort(this.props.sortKey);

  render() {
    const { isSorted, sortDirection, children } = this.props;

    return (
      <div
        onClick={this.handleClick}
        role={'button'}
        style={{ cursor: 'pointer' }}
        tabIndex={0}
      >
        {children}
        <span>
          <i
            className={
              `${css(
                styles.sortDown,
                isSorted && sortDirection === 'desc' && styles.sortActive,
              )} fa fa-sort-down`
            }
          />
          <i
            className={
              `${css(
                styles.sortUp,
                isSorted && sortDirection === 'asc' && styles.sortActive,
              )} fa fa-sort-up`
            }
          />
        </span>
      </div>
    );
  }
}

Sorter.propTypes = {
  isSorted: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortKey: PropTypes.string.isRequired,
  children: PropTypes.any,
};

Sorter.defaultPropTypes = {
  isSorted: false,
  sortDirection: 'desc',
};

export default Sorter;
