import { StyleSheet, css } from 'aphrodite';
import { darkGray, mediumGray } from '../colors';

export { css };
export default StyleSheet.create({
  sortDown: {
    color: mediumGray,
    position: 'relative',
    top: '1px',
    left: '0.63em',
  },
  sortUp: {
    color: mediumGray,
  },
  sortActive: {
    color: darkGray,
  },
});
