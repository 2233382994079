import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: ${props => (props.wide ? '100%' : 'fit-content')};
  min-width: fit-content;
  min-width:  -moz-fit-content;
  border-radius: 6px;
  border-style: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
  box-shadow: ${props => (props.flat ? 'none' : '0 1px 2px 0 rgba(0, 0, 0, 0.15) !important')};
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  ${props => props.segmentedActive && css`
    filter: brightness(92%);
  `}
  ${props => props.buttonStyle === 'primary' && css`
    background: var(--cy-green);
    color: #FFF !important;
    &:hover {
      background: #279354;
      color: #FFF !important;
    }
    &:active {
      background: #217f49;
      color: #FFF !important;
    }
  `}
  ${props => props.buttonStyle === 'secondary' && css`
    background: #FFF;
    color: var(--cy-dark-blue);
    border: 1px solid var(--cy-border-color);
    &:hover {
      background: #f2f2f2;
    }
    &:active {
      background: #e6e6e6;
    }
  `}
  ${props => props.buttonStyle === 'utility' && css`
    background: var(--cy-blue);
    color: #FFF !important;
    &:hover {
      background: #006be6;
      color: #FFF !important;
    }
    &:active {
      background: #005fcc;
      color: #FFF !important;
    }
  `}
  ${props => props.buttonStyle === 'danger' && css`
    background: var(--cy-red);
    color: #FFF !important;
    &:hover {
      background: #e92323;
      color: #FFF !important;
    }
    &:active {
      background: #dc1616;
      color: #FFF !important;
    }
  `}
  ${props => props.buttonStyle === 'link' && css`
    background: none;
    outline: none;
    color: var(--cy-blue);
    box-shadow: none !important;
    font-weight: 400;
    &:hover {
      cursor: 'pointer' !important,
      color: var(--cy-dark-gray) !important,
      textDecoration: 'underline' !important,
    }
  `}
  ${props => props.small && css`
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 2px;
  `}
  ${props => !props.padding && css`
    padding: 0;
  `}
`;

const StyledIcon = styled.i`
  margin-right: 4px;
  font-size: 14px;
`;

const UIButton = (props) => {
  const {
    buttonStyle,
    icon,
    small,
    flat,
    wide,
    children,
    onClick,
    isLoading,
    isDisabled,
    id,
    padding,
    segmentedActive,
  } = props;

  return (
    <StyledButton
      id={id}
      buttonStyle={buttonStyle}
      small={small}
      flat={flat}
      wide={wide}
      onClick={onClick}
      disabled={isLoading || isDisabled}
      padding={padding}
      segmentedActive={segmentedActive}
    >
      {icon && <StyledIcon className={`fa fa-${icon}`} />}

      {children}
    </StyledButton>
  );
};

UIButton.defaultProps = {
  buttonStyle: 'primary',
  icon: null,
  small: false,
  flat: false,
  wide: false,
  padding: true,
};

UIButton.propTypes = {
  buttonStyle: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
  flat: PropTypes.bool,
  wide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  padding: PropTypes.bool,
  segmentedActive: PropTypes.bool,
};

export default UIButton;