import R from 'ramda';

export const isBlank = R.either(R.isEmpty, R.isNil);
export const isPresent = R.pipe(isBlank, R.not);

export const capitalizeFirst = R.pipe(
  R.adjust(R.toUpper, 0),
  R.ifElse(
    R.is(Array),
    R.join(''),
    R.identity,
  ),
);

export const humanize = string => capitalizeFirst(R.replace(/_/g, ' ', string));

// Example: titleize('some_argument') => 'Some Argument'
export const titleize = string => R.join(' ', R.map(capitalizeFirst, R.split('_', string)));

export const preventDefaultAndCall = (e, func) => {
  e.preventDefault();
  e.stopPropagation();
  func();
};

export const boolAsYesNo = bool => (bool ? 'Yes' : 'No');

export const omitKey = (key, object) => (
  Object.entries(object)
    .filter(([k]) => k !== key)
    .reduce(
      (newObject, [k, v]) => Object.assign(newObject, { [k]: v }),
      {},
    )
);
