import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledFormLabel = styled.label`
  display: flex;
  font-size: 14px !important;
  margin-bottom: 8px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: var(--cy-dark-blue) !important;
  i {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
  }
  justify-content: ${props => props.justifyContent};
  align-items: center !important;
`;

const UIFormLabel = (props) => {
  const { children, htmlFor, justifyContent } = props;

  return (
    <StyledFormLabel
      htmlFor={htmlFor}
      justifyContent={justifyContent}
    >
      {children}
    </StyledFormLabel>
  );
};

UIFormLabel.defaultProps = {
  justifyContent: 'normal',
};

UIFormLabel.propTypes = {
  htmlFor: PropTypes.string,
  justifyContent: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UIFormLabel;