import React from 'react';
import PropTypes from 'prop-types';
import styles, { css } from './styles';

const Cell = ({
  alignRight,
  children,
  customClasses,
  id,
  isDisabled,
  isHeader,
  onClick,
  hasError,
  style,
}) => {
  const alignRightClass = alignRight ? ' chargify-data-table__cell--align-right' : '';
  const headerClass = isHeader ? '--header' : '';
  const errorClass = hasError ? '--has-error' : '';
  const desiredCustomClasses = customClasses === undefined ? '' : customClasses;
  const classNames = `chargify-data-table__cell${headerClass + alignRightClass + errorClass} ${desiredCustomClasses} skip_clickable`;

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* TODO get rid of this eslint-disable and remove the onClick from the td */
  return (
    <td
      className={`${css(isDisabled && styles.cellDisabled)} ${classNames}`}
      id={id}
      style={style}
      onClick={onClick}
    >
      {children}
    </td>
  );
};
  /* eslint-endable */

Cell.defaultPropTypes = {
  alignRight: false,
  children: '',
  id: '',
  isDisabled: false,
  isHeader: false,
  hasError: false,
  onClick: null,
  style: {},
};

Cell.propTypes = {
  alignRight: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isHeader: PropTypes.bool,
  onClick: PropTypes.func,
  hasError: PropTypes.bool,
  style: PropTypes.object,
  customClasses: PropTypes.string,
};

export default Cell;
