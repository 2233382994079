import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HelpIcon from '../HelpIcon';

const StyledDropdownMenu = styled.ul.attrs(() => ({
  className: 'cy-dropdown__menu',
}))`
  max-height: 250px;
  overflow-y: scroll;
  display: ${props => props.display};
  margin-top: 1rem;
  padding: 0;
  border-radius: 6px;
`;

const StyledDropdownMenuItem = styled.li`
  color: ${props =>
  (props.isSelected ? 'var(--cy-blue)' : 'var(--cy-gray-default)')};
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid var(--cy-border-color);
  :active {
    color: var(--cy-blue);
  }
  :hover {
    transition: background-color 0.5s ease;
    background-color: var(--cy-blue--lightest);
  }
  label {
    color: var(--cy-gray-default) !important;
  }
`;

const StyledLabel = styled.label.attrs(() => ({
  className: 'cy-form__label',
}))`
  cursor: pointer;
`;

const StyledButton = styled.button.attrs(() => ({
  className: 'cy-dropdown__button',
}))`
  color: var(--cy-dark-blue);
  min-width: 200px;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }
`;

const UIDropdownText = ({
                          label,
                          items,
                          children,
                          onChange,
                          defaultSelected,
                          enforcedSelectedItem,
                          isDisabled,
                        }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const [userSelectedItem, setUserSelectedItem] = useState(defaultSelected || items[0]);

  useEffect(() => {
    setUserSelectedItem(defaultSelected || items[0]);
  }, [items]);

  const handleSelectChange = (item) => {
    setUserSelectedItem(item);
    setOpen(false);
    onChange(item.value);
  };

  const selectedItem = enforcedSelectedItem || userSelectedItem;

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <React.Fragment>
      <StyledLabel>{label}</StyledLabel>
      <div className="cy-dropdown">
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
          disabled={isDisabled}
        >
          {selectedItem.label || label}
          {selectedItem.tooltip && <HelpIcon
            tooltip={selectedItem.tooltip}
          />}
          <i className="fa fa-angle-down" />
        </StyledButton>
      </div>
      <div ref={node}>
        {open && (
          <StyledDropdownMenu>
            {items.map((item, index) => (
              <StyledDropdownMenuItem
                key={index}
                onClick={() => handleSelectChange(item)}
                isSelected={selectedItem.value === item.value}
                withSpacing={!!item.text}
              >
                <StyledLabel>
                  {item.label}
                  {item.tooltip && <HelpIcon
                    tooltip={item.tooltip}
                  />}
                </StyledLabel>
                { item.text && <p>{item.text}</p> }
              </StyledDropdownMenuItem>
            ))}
          </StyledDropdownMenu>
        )}
      </div>
      {children}
    </React.Fragment>
  );
};

StyledDropdownMenu.defaultProps = {
  display: 'block',
  isDisabled: false,
};

UIDropdownText.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
  ).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onChange: PropTypes.func,
  defaultSelected: PropTypes.object,
  enforcedSelectedItem: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default UIDropdownText;