import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useUniqueId from '../hooks/useUniqueId';

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const UIForm = (props) => {
  const { children } = props;
  const id = useUniqueId('form-');

  return (
    <StyledForm id={id}>
      {children}
    </StyledForm>
  );
};

UIForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UIForm;