import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledLabelBadge = styled.span`
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  width: fit-content;
  width: -moz-fit-content;
  height: fit-content;
  height: -moz-fit-content;
  background: var(--cy-gray--lightest);
  color: var(--cy-black);
  margin-left: ${props => (props.marginLeft ? '5px' : 'none')};
  ${props => props.small && css`
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    padding: 4px;
  `}
  ${props => props.badgeStyle === 'positive' && css`
    background: var(--cy-green--lightest);
    color: var(--cy-black);
  `}
  ${props => props.badgeStyle === 'warning' && css`
    background: var(--cy-yellow--lightest);
    color: var(--cy-black);
  `}
  ${props => props.badgeStyle === 'danger' && css`
    background: var(--cy-red--lightest);
    color: var(--cy-red--darkest);
  `}
  ${props => props.badgeStyle === 'general' && css`
    background: var(--cy-blue--lightest);
    color: var(--cy-blue--darkest);
  `}
`;

const UIBadge = (props) => {
  const { badgeStyle, borderRadius, marginLeft, text, small, children } = props;

  return (
    <StyledLabelBadge
      small={small}
      badgeStyle={badgeStyle}
      marginLeft={marginLeft}
      borderRadius={borderRadius}
    >
      {text}
      {children}
    </StyledLabelBadge>
  );
};

UIBadge.defaultProps = {
  badgeStyle: null,
  borderRadius: null,
  marginLeft: false,
  small: false,
  text: null,
};

UIBadge.propTypes = {
  badgeStyle: PropTypes.string,
  borderRadius: PropTypes.string,
  marginLeft: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default UIBadge;