import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledFormTextInput = styled.input`
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  min-height: unset!important;
  max-height: 16px;
  &[disabled] {
    background-color: unset !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => `${props.small ? '8px 10px' : '8px 12px'} !important`};
  background: #FFFFFF !important;
  border: ${props => `1px solid ${props.hasError ? 'var(--cy-red)' : 'var(--cy-border-color)'} !important`};
  border-radius: 6px !important;
  font-size: ${props => `${props.small ? '12px' : '14px'} !important`};
  line-height: 16px !important;
  transition: all 0.02s ease-in-out;
  &:hover {
    border: 1px solid var(--cy-blue) !important;
  }
  &:focus {
    border: 1px solid var(--cy-blue) !important;
  }
  &:focus-within {
    border: 1px solid var(--cy-blue) !important;
  }
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &[disabled]:hover {
    border: 1px solid var(--cy-gray--light) !important;
  }
  &::placeholder {
    color: var(--cy-dark-blue);
    opacity: 0.56;
  }
`;

const InputPrefix = styled.div`
  margin-right: 4px;
  min-width: fit-content;
  min-width: -moz-fit-content;
  display: flex;
`;

const InputSuffix = styled.div`
  margin-left: 4px;
  min-width: fit-content;
  min-width: -moz-fit-content;
  display: flex;
`;

const UIFormTextInput = (props) => {
  const {
    id,
    isDisabled,
    hasError,
    name,
    onChange,
    placeholder,
    readOnly,
    small,
    prefix,
    suffix,
    value,
  } = props;

  return (
    <Wrapper
      small={small}
      hasError={hasError}
      prefix={prefix}
      suffix={suffix}
      disabled={isDisabled}
    >
      {prefix && (<InputPrefix>{prefix}</InputPrefix>)}
      <StyledFormTextInput
        type="text"
        id={id}
        name={name}
        onChange={e => onChange(name, e.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        disabled={isDisabled}
      />
      {suffix && (<InputSuffix>{suffix}</InputSuffix>)}
    </Wrapper>
  );
};

UIFormTextInput.defaultProps = {
  errorMessage: null,
  hasError: false,
  hint: null,
  isDisabled: false,
  labelBadge: null,
  placeholder: '',
  readOnly: false,
  small: false,
  prefix: null,
  suffix: null,
  value: '',
};

UIFormTextInput.propTypes = {
  id: PropTypes.string,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  small: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UIFormTextInput;