import React, { useContext } from 'react';
import { UIRadioGroup, UIRadio } from  '../../studio';
import { CancellationSettings } from '../contexts/cancellation_settings';

const DisplayReasonCodes = () => {
  const { reasonCodes, reasonCode, setReasonCode } = useContext(CancellationSettings);
  return (
    <>
      <p>Would you mind letting us know why you're cancelling?</p>
      <UIRadioGroup column>
        {reasonCodes.map(rc => (<UIRadio
            id={rc.id}
            key={rc.id}
            label={rc.description}
            name="allowNegativeBalance"
            value={rc.code}
            onChange={(_, val) => setReasonCode(val)}
            isChecked={rc.code === reasonCode}
          />),
        )}
      </UIRadioGroup>
    </>
  );
};

export default DisplayReasonCodes;