import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import UIFormItem from './UIFormItem';
import UIFormLabel from './UIFormLabel';
import UIFormTextInput from './UIFormTextInput';
import UIBadge from './UIBadge';

import useUniqueId from '../hooks/useUniqueId';

const StyledErrorMessage = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  color: var(--cy-red);
`;

const StyledHint = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
`;

const StyledSpan = styled.span`
  overflow-wrap: anywhere;
  ${props => !(props.readModeValue || props.value) && css`
    color: var(--cy-disabled-font-color);
    font-style: italic;
  `}
`;

const UIFormItemWithInput = (props) => {
  const {
    errorMessage,
    id,
    isDisabled,
    hint,
    label,
    labelBadge,
    labelBadgeStyle,
    alignBadgeRight,
    name,
    onChange,
    placeholder,
    prefix,
    small,
    suffix,
    value,
    readMode,
    readModeValue,
  } = props;

  const fallbackId = useUniqueId('input-');
  const inputId = id || fallbackId;

  return (
    <UIFormItem>
      {label && (
        <UIFormLabel htmlFor={inputId} justifyContent={alignBadgeRight ? 'space-between' : null}>
          <span>{label}</span>
          {labelBadge && (
            <UIBadge small text={labelBadge} badgeStyle={labelBadgeStyle} marginLeft />
          )}
        </UIFormLabel>
      )}

      {readMode ? (
        <StyledSpan
          readModeValue={readModeValue}
          value={value}
        >
          {readModeValue || value || 'None'}
        </StyledSpan>
      ) : (
        <React.Fragment>
          <UIFormTextInput
            type="text"
            hasError={!!errorMessage}
            id={inputId}
            isDisabled={isDisabled}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            prefix={prefix}
            small={small}
            suffix={suffix}
            value={value}
          />

          {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}

          {hint && <StyledHint>{hint}</StyledHint>}
        </React.Fragment>
      )}
    </UIFormItem>
  );
};

UIFormItemWithInput.defaultProps = {
  errorMessage: null,
  hint: null,
  isDisabled: false,
  label: null,
  labelBadge: null,
  labelBadgeStyle: null,
  alignBadgeRight: false,
  placeholder: '',
  prefix: null,
  small: false,
  suffix: null,
  readMode: false,
  readModeValue: null,
};

UIFormItemWithInput.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  labelBadge: PropTypes.string,
  labelBadgeStyle: PropTypes.string,
  alignBadgeRight: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  small: PropTypes.bool,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readMode: PropTypes.bool,
  readModeValue: PropTypes.string,
};

export default UIFormItemWithInput;
