import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import { isPresent } from '../utils/helpers';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CancellationSettings } from './contexts/cancellation_settings';
import { createCancellation } from './actions';
import ReasonCodes from './ReasonCodes';
import * as T from './types';
import { UIForm, UIFormRow, UIDropdownText, UIButton,
  UIFormItem, UIFormLabel, UIFormItemWithInput } from '../studio';
import InfoSection from './InfoSection';
import { CANCEL_IMMEDIATELY, CANCEL_AT_END_OF_PERIOD,
  CANCEL_ON_SPECIFIC_DATE, CANCELLATION_OPTIONS } from './constants';

const StyledContent = styled.div`
  position: relative;
`;

const StyledFormItem = styled.div.attrs(() => ({
  className: 'cy-form__item',
}))`
  width: ${props => props.width || 'auto'};
`;

const StyledCondition = styled.div`
 margin-left: 10px;
 padding: 8px 0px;
`;

const StyledGoBackAction = styled.a`
 margin-left: 10px;
 padding: 8px 0px;
`;

const SubscriptionCancellation = ({
  subscription,
  reasonCodes,
  reasonCodesSettingPageUrl,
  endOfPeriods,
  immediatelyOptionEnabled,
  endOfPeriodOptionEnabled,
  onSpecificDateOptionEnabled }) => {
  const [reasonCode, setReasonCode] = useState('');
  const [cancellationMessage, setCancellationMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [scheduledCancellationAt, setScheduledCancellationAt] = useState(moment());
  const [cancelAtEndOfPeriod, setCancelAtEndOfPeriod] = useState(false);
  const [formattedScheduledCancellationAt, setFormattedScheduledCancellationAt] = useState('');
  const [cancellationOption, setCancellationOption] = useState(CANCEL_IMMEDIATELY);
  const [cancellationAvailableOptions, setCancellationAvailableOptions] = useState(CANCELLATION_OPTIONS);
  const isCancelImmediately = cancellationOption === CANCEL_IMMEDIATELY;
  const isCancelAtEndOfPeriod = cancellationOption === CANCEL_AT_END_OF_PERIOD;
  const isCancelOnSpecificDate = cancellationOption === CANCEL_ON_SPECIFIC_DATE;

  const removeNotAvailableOption = (label, options) => {
    return (options.filter(
      option => option.label !== label
    ));
  };

  useEffect(() => {
    let availableOptions = cancellationAvailableOptions;
    if(!immediatelyOptionEnabled) {
      availableOptions = removeNotAvailableOption(CANCEL_IMMEDIATELY, availableOptions);
    }

    if(!endOfPeriodOptionEnabled) {
      availableOptions = removeNotAvailableOption(CANCEL_AT_END_OF_PERIOD, availableOptions);
    }

    if(!onSpecificDateOptionEnabled) {
      availableOptions = removeNotAvailableOption(CANCEL_ON_SPECIFIC_DATE, availableOptions);
    }

    setCancellationAvailableOptions(availableOptions);
  }, []);

  useEffect(() => {
    setCancellationOption(cancellationAvailableOptions[0].label);
  }, [cancellationAvailableOptions]);

  const settings = {
    reasonCodes,
    reasonCodesSettingPageUrl,
    reasonCode,
    setReasonCode,
  };

  const params = {
    subscription: {
      reasonCode: reasonCode,
      cancellationMessage: cancellationMessage,
      scheduledCancellationAt: formattedScheduledCancellationAt,
    },
    commit: cancellationOption,
    cancelAtEndOfPeriod: cancelAtEndOfPeriod,
  };

  const onSuccess = () => {
    window.location.assign(`${subscription.linkToSubscriptions}`);
  };

  const onFail = (res) => {
    if (res.response) {
      setErrors([camelizeKeys(res.response.data.errors)]);
    } else {
      setErrors(['Unexpected error occurred.']);
    }
  };

  const validate = () => {
    const currentErrors = [];

    if (!isEmpty(reasonCodes) && isEmpty(reasonCode) && !isCancelImmediately) {
      currentErrors.push('A reason code must be provided for cancelling the subscription.');
    }

    if (!isEmpty(currentErrors)) {
      setErrors(currentErrors);
      return false;
    }

    return true;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }
    createCancellation(subscription.linkToCancelSubscription, decamelizeKeys(params), onSuccess, onFail);
  };

  useEffect(() => {
    if (isCancelOnSpecificDate) {
      setScheduledCancellationAt(moment());
    } else if (isCancelAtEndOfPeriod) {
      setScheduledCancellationAt(endOfPeriods[0].value);
    }
    setCancelAtEndOfPeriod(isCancelAtEndOfPeriod);
  }, [cancellationOption]);

  useEffect(() => {
    if (isEmpty(subscription.formattedCurrentPeriodEndsAt)) {
      return;
    }
    const splittedDate = subscription.formattedCurrentPeriodEndsAt.split(' ');
    if (moment.isMoment(scheduledCancellationAt)) {
      splittedDate[0] = scheduledCancellationAt.format('DD MMMM YYYY');
    } else {
      const date = scheduledCancellationAt.split('T')[0];
      splittedDate[0] = moment(date).format('DD MMMM YYYY');
    }
    setFormattedScheduledCancellationAt(splittedDate.join(' '));
  }, [scheduledCancellationAt]);

  const isTimingDropdownDisabled = ['Past Due', 'Expired', 'Paused', 'On Hold'].includes(subscription.state);

  const availableCancellationOptions = () => {
    return cancellationAvailableOptions;
  };

  return (
    <StyledContent>
      <UIForm>
        {isPresent(errors) && (
          <div className="cy-alert cy-alert--danger add-margin-bottom">
            <div className="alert__content">{errors.join(', ')}</div>
          </div>
        )}
        <UIFormRow>
          <UIFormItem>
            <CancellationSettings.Provider value={settings}>
              <ReasonCodes
                reasonCodesEmpty={isEmpty(reasonCodes)}
              />
            </CancellationSettings.Provider>
          </UIFormItem>
        </UIFormRow>
        <UIFormRow>
          <UIFormItemWithInput
            label="Cancellation Notes"
            labelBadge="Optional"
            id="cancellationMessage"
            onChange={(_, val) => setCancellationMessage(val)}
            name="cancellationMessage"
            value={cancellationMessage}
            placeholder="Notes..."
            inline
            hint="Thank you for giving us a try! Is there anything we can do better in the future?"
          />
        </UIFormRow>
        <UIFormRow>
          <StyledFormItem>
            <UIDropdownText
              label="Collection Timing"
              items={availableCancellationOptions()}
              onChange={setCancellationOption}
              isDisabled={isTimingDropdownDisabled}
            />
          </StyledFormItem>
        </UIFormRow>
        { (isCancelOnSpecificDate &&
          moment.isMoment(scheduledCancellationAt))
        && <UIFormRow>
          <UIFormItem>
            <UIFormLabel>Cancellation Date</UIFormLabel>
            <DatePicker
              selected={scheduledCancellationAt}
              minDate={moment()}
              onChange={setScheduledCancellationAt}
              placeholderText="Select date"
            />
          </UIFormItem>
        </UIFormRow>
        }
        { isCancelAtEndOfPeriod
        && <UIFormRow>
          <UIFormItem>
            <UIDropdownText
              label="Cancellation Period"
              items={endOfPeriods}
              onChange={setScheduledCancellationAt}
              defaultValue={endOfPeriods[0].value}
            />
          </UIFormItem>
        </UIFormRow>
        }
        <InfoSection
          isCancelOnSpecificDate={isCancelOnSpecificDate}
          isCancelImmediately={isCancelImmediately}
          formattedScheduledCancellationAt={formattedScheduledCancellationAt}
          subscriptionState={subscription.state}
          isSchedulingCancellationAvailable={isTimingDropdownDisabled}
        />
        <UIFormRow>
          <UIButton onClick={onFormSubmit} buttonStyle="primary">
            Confirm Cancellation
          </UIButton>
          <StyledCondition>or</StyledCondition>
          <StyledGoBackAction href={subscription.linkToSubscriptions} className="cy-link">
            Go back
          </StyledGoBackAction>
        </UIFormRow>
      </UIForm>
    </StyledContent>
  );
};

SubscriptionCancellation.propTypes = {
  subscription: T.subscription.isRequired,
  reasonCodes: T.reasonCodes.isRequired,
  reasonCodesSettingPageUrl: PropTypes.string.isRequired,
  endOfPeriods: T.endOfPeriods.isRequired,
  immediatelyOptionEnabled: PropTypes.bool.isRequired,
  endOfPeriodOptionEnabled: PropTypes.bool.isRequired,
  onSpecificDateOptionEnabled: PropTypes.bool.isRequired
};

export default SubscriptionCancellation;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-subscription-cancellation');
  if(node) {
    const data = JSON.parse(node.getAttribute('data-camelized-props'));

    ReactDOM.render(
      <SubscriptionCancellation {...data} />,
      node.appendChild(document.createElement('div')),
    )
  }
});