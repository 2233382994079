import PropTypes from 'prop-types';
import React from 'react';

const Pager = ({ currentPage, totalEntries, pageLimit, onClick }) => {
  let maxPage = Math.ceil(totalEntries / pageLimit);
  maxPage = maxPage > 0 ? maxPage : 1;
  const prev = currentPage - 1;
  const next = currentPage + 1;

  const requestPage = (requestedPage) => {
    if (requestedPage <= maxPage && requestedPage > 0) {
      onClick(requestedPage);
    }
  };

  return (
    <div className="pagination">
      <button
        type="button"
        className="button pagination__button"
        disabled={prev < 1}
        onClick={() => requestPage(prev)}
      >
        <i className="fa fa-angle-left" />
      </button>

      <div className="pagination__page__wrapper">
        <span className="pagination__page__number">{currentPage}</span>
        &nbsp;of&nbsp;
        <span className="pagination__page__number">{maxPage}</span>
      </div>

      <button
        type="button"
        className="button pagination__button"
        disabled={next > maxPage}
        onClick={() => requestPage(next)}
      >
        <i className="fa fa-angle-right" />
      </button>
    </div>
  );
};

Pager.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Pager.defaultProps = {
  pageLimit: 6,
};

export default Pager;
