// BASE
const blue = '#439AEA';
const green = '#47C486';
const red = '#FF4949';
const white = '#FFFFFF';
const yellow = '#EFC663';

const lightRed = 'rgba(255, 73, 73, .10)';
const lightGray = '#F7F7F7';
const lightGreen = '#7ED5AA';

// ACCENT
const accentGray = '#808080';

// MEDIUM
const disabledGray = '#ADADAD';
const mediumGray = '#DDDDDD';
const mediumWhite = '#F5F5F5';
const mediumYellow = '#FFCF5E';

// DARK
const darkBlue = '#1981E1';
const darkGray = '#454F5B';
const darkGray2 = '#454F5B';
const darkGreen = '#34A46C';
const darkRed = '#E20000';
const darkYellow = '#BF8D14';

export {
  // BASE
  blue,
  green,
  red,
  white,
  yellow,
  // ACCENT
  accentGray,
  // LIGHT
  lightRed,
  lightGray,
  lightGreen,
  // MEDIUM
  disabledGray,
  mediumWhite,
  mediumGray,
  mediumYellow,
  // DARK
  darkBlue,
  darkGray,
  darkGray2,
  darkGreen,
  darkRed,
  darkYellow,
};
