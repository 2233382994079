import React from 'react';

function isChecked(quantity) {
  return quantity == 1
};

function AllocationChangeModal({ charge, setComponent, toggleModal }) {
  const [quantity, setQuantity] = React.useState(charge.startingQuantity);
  const [pricePointId, setPricePointId] = React.useState(charge.pricePointId);

  const closeModal = () => toggleModal(false);

  const handleUpdate = () => {
    setComponent({
      componentId: charge.componentId,
      quantity: quantity,
      pricePointId: pricePointId
    });
    closeModal();
  };

  const handleSetQuantity = event => setQuantity(event.target.value);
  const handleSetPricePointId = event => setPricePointId(event.target.value);
  const handleToggleOnOffComponent = () => {
    setQuantity(quantity == 1 ? 0 : 1);
  };

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-content" style={{ maxWidth: "500px" }}>
        <div className="modal-header">
          <div
            className="close"
            onClick={closeModal}
          >
            &times;
          </div>
          <p>Change Amount for {charge.componentName} Add-On</p>
        </div>

        <div className="modal-body">
          {charge.componentName}

          <div style={{ display: "flex" }}>
            {charge.editable && charge.componentType === 'OnOffComponent' && (
              <div style={{ width: "100%", paddingRight: "5px" }}>
                <input
                  type="checkbox"
                  name="quantity"
                  checked={isChecked(quantity)}
                  value={isChecked(quantity)}
                  onChange={handleToggleOnOffComponent}
                />
              </div>
            )}

            {charge.editable && charge.componentType !== 'OnOffComponent' && (
              <div style={{ width: "100%", paddingRight: "5px" }}>
                <input type="text" value={quantity} onChange={handleSetQuantity} />
              </div>
            )}

            {charge.pricePointSelectable && (
              <div style={{ width: "100%", paddingLeft: "5px" }}>
                <select
                  onChange={handleSetPricePointId}
                  name="pricePoint"
                >
                  {charge.pricePoints.map((pricePoint) => (
                    <option
                      key={pricePoint.id}
                      value={pricePoint.id}
                    >
                      {pricePoint.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="modal-footer" style={{ display: "inline" }}>
          {quantity == 0 && (
            <p style={{ fontStyle: "italic" }}>
              Clearing this will remove it from the preview and you'll need to restart to get it back.
            </p>
          )}
          <div className="button" style={{ float: "right" }} onClick={handleUpdate}>Update Totals</div>
          <span style={{ clear: "left", display: "block" }} />
        </div>
      </div>
    </div>
  );
};

export default AllocationChangeModal;
