import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Body from './Body';
import Row from './Row';
import Cell from './Cell';

class DataTable extends Component {
  render() {
    const { id, children } = this.props;

    return (
    <div className="chargify-card--is-scrollable">
      <table className="chargify-data-table" id={id} ref={(table) => { this.dataTable = table; }}>
        {children}
      </table>
    </div>
    );
  }
}

DataTable.defaultPropTypes = {
  id: '',
};

DataTable.propTypes = {
  id: PropTypes.string,
  children: PropTypes.array.isRequired,
};

export default DataTable;
export { Header, Body, Row, Cell };
