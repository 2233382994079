import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ children, style }) => (
  <tbody className="chargify-data-table__body" style={style}>
    {children}
  </tbody>
);

Body.defaultPropTypes = {
  style: {},
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Body;
