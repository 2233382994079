import PropTypes from 'prop-types';

const endOfPeriod = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const endOfPeriods = PropTypes.arrayOf(endOfPeriod);

const reasonCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
});

const reasonCodes = PropTypes.arrayOf(reasonCode);

const subscription = PropTypes.shape({
  formattedCurrentPeriodEndsAt: PropTypes.string,
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  linkToCancelSubscription: PropTypes.string.isRequired,
  linkToSubscriptions: PropTypes.string.isRequired,
});

export {
  endOfPeriods,
  reasonCodes,
  subscription,
};