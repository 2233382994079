import R from 'ramda';
import { decamelize } from 'humps';

export const buildCurrency = (cents, formatting) => ({
  ...formatting,
  cents: Math.abs(cents),
  sign: cents >= 0 ? 'POSITIVE' : 'NEGATIVE',
});

export const splitDollarsAndCents = (currency) => {
  const { cents, decimalPlaces } = currency;
  if (decimalPlaces < 1) {
    return {
      ...currency,
      dollars: cents,
      cents: 0,
    };
  }
  const divisor = decimalPlaces > 0 ? (10 ** decimalPlaces) : 1;
  const dollars = Math.trunc(cents / divisor);
  const justCents = cents % divisor;
  return {
    ...currency,
    dollars,
    cents: justCents,
  };
};

// Uses both a positive and a negative lookahead to get exactly 3 digits and add
// a comma if needed:
// For example:
//   "1" -> "1"
//   "123" -> "123"
//   "12345" -> "12,345"
//   "12345678" -> "12,345,678"
export const addDelimiterEveryThreeDigits = (delim, str) => (
  str.replace(/\B(?=(\d{3})+(?!\d))/g, delim)
);

export const addDelimiter = (currency) => {
  const { dollars, delimiter } = currency;
  const str = dollars.toFixed();
  const dollarStr = addDelimiterEveryThreeDigits(delimiter, str);
  return { ...currency, dollarStr };
};

const dollarsAndCents = (currency) => {
  const { cents, dollarStr, decimalPlaces, separator, showCents } = currency;
  if (!showCents) return dollarStr;

  const centPad = R.times(() => '0', decimalPlaces).join('').concat(cents);
  const normalizedCents = centPad.substring(centPad.length - decimalPlaces, centPad.length);
  const fullValue = decimalPlaces > 0 ? `${dollarStr}${separator}${normalizedCents}` : dollarStr;
  return fullValue;
};

const addSymbol = ({ symbol, symbolPosition }, str) => (
  symbolPosition === 'LEFT' ? `${symbol}${str}` : `${str}${symbol}`
);

export const toString = (currency) => {
  const formattedValue = dollarsAndCents(currency);
  const withSymbol = addSymbol(currency, formattedValue);
  return currency.sign === 'POSITIVE' ? withSymbol : `-${withSymbol}`;
};

export const defaultCurrencyFormatting = () => ({
  delimiter: ',',
  decimalPlaces: 2,
  separator: '.',
  symbol: '$',
  symbolPosition: 'LEFT',
  showCents: true,
});

// Build formatting object merging argument and defaults object then builds a
// currency object. The function then transforms the object to a string, adding
// and updating properties to the object in each function call
export const asCurrency = R.curry((formatting, cents) => {
  // merge defaults with `formatting`. NOTE: This is a shallow merge.
  const withDefaults = R.merge(defaultCurrencyFormatting(), formatting);

  return R.pipe(
    buildCurrency,
    splitDollarsAndCents,
    addDelimiter,
    toString,
  )(cents, withDefaults);
});

const mod100 = R.flip(R.modulo)(100);
const lessThan100 = R.flip(R.lt)(10000);
const notDivisibleBy100 = R.pipe(mod100, R.equals(0), R.not);

export const asCurrencyConditionalCents = R.curry((formatting, cents) => {
  const showCents = R.allPass([
    lessThan100,
    R.either(notDivisibleBy100, R.equals(0)),
  ])(Math.abs(cents));

  return asCurrency(
    R.merge(formatting, { showCents }),
    cents,
  );
});

const isInteger = n => typeof n === 'number' && isFinite(n) && Math.floor(n) === n;

const lt = R.flip(R.lt);
const gte = R.flip(R.gte);

const addCharToStringUntilLength = (char, num) => R.until(
  R.compose(gte(num), R.length),
  R.append(char),
);

export const rightPad = R.curry((char, num, str) => R.compose(
  R.ifElse(R.is(Array), R.join(''), R.identity),
  R.ifElse(
    R.compose(lt(num), R.length),
    addCharToStringUntilLength(char, num),
    R.identity,
  ),
)(str));

export const beginsWith = R.curry((prefix, list) => R.equals(
  prefix,
  R.head(list),
));

export const removeLeadingZeros = R.until(R.complement(beginsWith('0')), R.tail);
const removeTrailingZeros = R.compose(R.reverse, removeLeadingZeros, R.reverse);

export const roundTo = (maxDecimalPlaces, num) => {
  const str = R.toString(num);
  if (isInteger(num)) return str;

  // 1. Split the string at the "."
  // 2. Build an object with the integer and fractional parts
  // 3. Don't change the integer part
  // 4. Change the fractional part using these steps:
  //      1. Right pad with "0"s to the max number needed
  //      2. Take the max number needed from the beginning of the string
  //      3. Remove any trailing zeros
  // 5. Build the final string
  return R.compose(
    ({ integer, fractional }) => `${integer}.${fractional}`,
    R.evolve({
      integer: R.identity,
      fractional: R.compose(
        removeTrailingZeros,
        R.take(maxDecimalPlaces),
        rightPad('0', maxDecimalPlaces),
      ),
    }),
    ary => ({ integer: R.head(ary), fractional: R.last(ary) }),
    R.split('.'),
  )(str);
};

export const defaultPercentFormatting = () => ({
  decimalPlaces: 1,
  spaceBeforeSymbol: false,
});

export const asPercent = (value, formatting) => {
  const withDefaults = R.merge(defaultPercentFormatting(), formatting);
  const { decimalPlaces, spaceBeforeSymbol } = withDefaults;
  const symbol = spaceBeforeSymbol ? ' %' : '%';

  if (isInteger(value)) {
    return `${value}${symbol}`;
  }

  return `${value.toFixed(decimalPlaces)}${symbol}`;
};

export const asCurrencyOrPercent = (tooltipFormat, value, currencyFormatting) => (
  tooltipFormat === 'currency'
    ? asCurrency(currencyFormatting, value)
    : asPercent(value)
);

export const percentChange = (original, newValue) => (newValue / original * 100) - 100;

export const openUrlInNewTab = url => window.open(url, '_blank');

export const buildUrlParams = (obj, decamelizeKey = true) => (
  R.tail(
    R.reduce(
      (acc, [k, v]) => `${acc}&${decamelizeKey ? decamelize(k) : k}=${v}`,
      '',
      R.pipe(R.reject(R.isEmpty), R.toPairs)(obj),
    ),
  )
);

export const notNil = R.complement(R.isNil);
