import axios from 'axios';

export const csrfToken = () => {
  try {
    return document.head.querySelector('[name=csrf-token]').content;
  } catch (e) {
    return '';
  }
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken(),
};

const defaultHeadersWithNoContentType = {
  'X-CSRF-Token': csrfToken(),
};

const javascriptHeaders = () => {
  return {
    'Accept': 'text/javascript',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken(),
    'X-Requested-With': 'XMLHttpRequest'
  }
};

const headersToUse = (format) => {
  switch (format) {
    case 'api':
      return defaultHeaders;
    case 'javascript':
      return javascriptHeaders();
    default:
      return defaultHeadersWithNoContentType;
  }
};

export const api = ({ format = 'api' } = {}) => (
  axios.create({
    headers: {
      ...headersToUse(format),
    },
  })
);
