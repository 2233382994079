import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledFormItem = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 24px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0 !important;
  }
`;

const StyledRadioGroup = styled.div`
  display: flex;
  flex-flow: ${props => (props.column ? 'column' : 'row')};
  justify-content: flex-start;
  & > div {
    margin-right: 20px;
  }
`;

const UIRadioGroup = (props) => {
  const { children, column } = props;

  return (
    <StyledFormItem>
      <StyledRadioGroup column={column}>
        {children}
      </StyledRadioGroup>
    </StyledFormItem>
  );
};

UIRadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  column: PropTypes.bool,
};

export default UIRadioGroup;