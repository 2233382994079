import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledFormRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-bottom: 24px;
  ${props => props.wrapItems && css`
    flex-wrap: wrap;
    margin-bottom: 15px;
  `}
`;

const UIFormRow = (props) => {
  const { children, wrapItems } = props;

  return (
    <StyledFormRow wrapItems={wrapItems}>
      {children}
    </StyledFormRow>
  );
};

UIFormRow.defaultProps = {
  wrapItems: false,
};

UIFormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  wrapItems: PropTypes.bool,
};

export default UIFormRow;