import React from 'react';
import PropTypes from 'prop-types';
import { disabledGray, lightGray } from '../colors';
import { isPresent } from '../../utils/helpers';

const Row = ({
  children,
  id,
  isClickable,
  isDisabled,
  isHidden,
  onClick,
  isGrayedOut,
  noHover,
  verticalAlign,
  customClass,
}) => {
  const rowStyle = {
    ...isClickable && { cursor: 'pointer' },
    ...isHidden && { visibility: 'hidden' },
    ...isGrayedOut && {
      backgroundColor: lightGray,
    },
    ...isDisabled && { color: disabledGray },
    ...isPresent(verticalAlign) && { verticalAlign },
  };

  return (
    <tr
      className={`chargify-data-table__row${noHover ? '--no-hover' : ''} ${customClass}`}
      id={id}
      onClick={onClick}
      style={rowStyle}
    >
      {children}
    </tr>
  );
};

Row.defaultPropTypes = {
  id: '',
  isClickable: false,
  isHidden: false,
  onClick: null,
  noHover: false,
  customClass: '',
};

Row.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  isClickable: PropTypes.bool,
  isHidden: PropTypes.bool,
  onClick: PropTypes.func,
  isGrayedOut: PropTypes.bool,
  isDisabled: PropTypes.bool,
  noHover: PropTypes.bool,
  verticalAlign: PropTypes.string,
  customClass: PropTypes.string,
};

export default Row;
