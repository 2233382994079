import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledFormItem = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 24px;
  width: 100%;
  &:first-of-type {
    margin-left: 0 !important;
  }
  ${props => props.inline && css`
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const UIFormItem = (props) => {
  const { inline, children } = props;

  return (
    <StyledFormItem inline={inline}>
      {children}
    </StyledFormItem>
  );
};

UIFormItem.defaultProps = {
  inline: false,
};

UIFormItem.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default UIFormItem;