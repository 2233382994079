import PropTypes from 'prop-types';
import React from 'react';
import styles, { css } from './styles';

const dimension = (isSmall, isMedium, isLarge) => {
  if (isSmall) {
    return 25;
  }
  if (isMedium) {
    return 50;
  }
  if (isLarge) {
    return 100;
  }
  return 25;
};

const LoadingIndicator = ({
  children,
  isFullHeight,
  isLarge,
  isLoading,
  isMedium,
  isSmall,
  minHeight,
}) => {
  const containerStyles = {
    minHeight: `${minHeight}px`,
    ...(isFullHeight && { height: '100%' }),
  };
  const size = dimension(isSmall, isMedium, isLarge);

  return (
    <div style={containerStyles} className={css(isLoading && styles.container)}>
      {isLoading && (
        <svg version="1.1" height={size} width={size} viewBox="0 0 600 600">
          <g className={css(styles.logo)}>
            <path
              d="M144.06 155a12.82 12.82 0 0 0 12.82-12.83V7.53c0-5.42-6.52-9.29-11.28-6.71L11.27 73.67A21.59 21.59 0 0 0 0 92.61v62.79c0 5.71 6.17.62 11.12-2.22l66.5-42.81a7.42 7.42 0 0 1 11.11 6.43v25.39c.011 7.078 5.752 12.81 12.83 12.81h42.5z"
              fill="#47C486"
            />
            <path
              d="M12.92 163.56C5.838 163.566.1 169.308.1 176.39v134.66c0 5.42 6.52 9.3 11.28 6.72l134.34-72.85A21.59 21.59 0 0 0 157 226v-46.33c-.1-16.33-7-14-12-11.15l-65.64 39.69a7.41 7.41 0 0 1-11.11-6.43v-25.39a12.83 12.83 0 0 0-12.83-12.83h-42.5z"
              fill="#2196F3"
            />
          </g>
          <g className={css(styles.indicators)}>
            <circle cx="80" cy="90" r="20" fill="#47C486" />
            <circle cx="80" cy="240" r="20" fill="#2196F3" />
          </g>
        </svg>
      )}
      {!isLoading && children}
    </div>
  );
};

LoadingIndicator.defaultProps = {
  isFullHeight: false,
  isSmall: false,
  isMedium: true,
  isLarge: false,
  isLight: false,
};

LoadingIndicator.propTypes = {
  children: PropTypes.node,
  isFullHeight: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  minHeight: PropTypes.number.isRequired,
};

export default LoadingIndicator;
