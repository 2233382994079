import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from '../shared/Spinner';

function NextBillingAmount({ shortname, subscriptionId }) {
  const [amount, setAmount] = React.useState('');

  React.useEffect(() => {
    const url = "/s/" + shortname + "/subscriptions/" + subscriptionId + "/next_billing_amount";

    $.ajax({
      context: this,
      url: url,
      success: function(result) {
        setAmount(result.amount);
      },
      error: function(result) {
        setAmount('N/A');
      }
    });
  }, []);

  if(amount === ''){
    return <Spinner />;
  }

  return (
    <span>{amount}</span>
  );
}

export default NextBillingAmount;

document.addEventListener('DOMContentLoaded', () => {
      const nodes = document.getElementsByClassName("react-next-billing-amount");
      nodes.forEach(function (node) {
        let data = JSON.parse(node.getAttribute('data-camelized-props'));
        ReactDOM.render(
            <NextBillingAmount {...data} />,
            node.appendChild(document.createElement('div')),
        );
      });
    }
  );
