const CANCEL_IMMEDIATELY = 'Cancel Immediately';
const CANCEL_AT_END_OF_PERIOD = 'Cancel At End Of Period';
const CANCEL_ON_SPECIFIC_DATE = 'Cancel on Specific Date';
const CANCELLATION_OPTIONS = [
  {
    label: CANCEL_IMMEDIATELY,
    value: CANCEL_IMMEDIATELY,
  },
  {
    label: CANCEL_AT_END_OF_PERIOD,
    value: CANCEL_AT_END_OF_PERIOD,
  },
  {
    label: CANCEL_ON_SPECIFIC_DATE,
    value: CANCEL_ON_SPECIFIC_DATE,
  },
];

export {
  CANCEL_IMMEDIATELY,
  CANCEL_AT_END_OF_PERIOD,
  CANCEL_ON_SPECIFIC_DATE,
  CANCELLATION_OPTIONS,
};