import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { Tooltip } from 'pivotal-ui/react/tooltip';
import { OverlayTrigger } from 'pivotal-ui/react/overlay-trigger';

const wrapWithLink = url => component => (
  url ? <a className="nocolor" href={url} target="_blank" rel="noopener noreferrer">{component}</a> : component
);

const wrapWithTooltip = message => component => (
  message ?
    <OverlayTrigger placement="right" overlay={<Tooltip size="lg">{message}</Tooltip>}>
      {component}
    </OverlayTrigger>
    : component
);

const HelpIcon = ({ url, tooltip, className, iconClassName }) => {
  if (url === undefined && tooltip === undefined) return null;

  const icon = <i className={`${iconClassName} ${className || ''}`} aria-hidden="true" />;

  return R.pipe(
    wrapWithLink(url),
    wrapWithTooltip(tooltip),
    R.identity,
  )(icon);
};

HelpIcon.defaultProps = {
  url: undefined,
  tooltip: undefined,
  iconClassName: 'fa fa-info-circle',
};

HelpIcon.propTypes = {
  url: PropTypes.string,
  tooltip: PropTypes.node,
  iconClassName: PropTypes.string,
};

export default HelpIcon;