import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useUniqueId from '../hooks/useUniqueId';
import WithTooltip from '../WithTooltip';

const StyledIcon = styled.i`
  color: var(--cy-dark-blue--dark);
`;

const UIRadio = (props) => {
  const { id, helpText, helpTooltip, name, label, value, isChecked, isDisabled, onChange } = props;
  const uniqueId = useUniqueId('radio-');
  const actualId = id || uniqueId;
  const actualValue = value || isChecked;
  const handleChange = e => onChange(name, e.target.value);

  const toolTip = helpText && helpTooltip && (
    <WithTooltip
      message={helpText}
      size="md"
      addTooltip
    >
      &nbsp;<StyledIcon className="fa fa-info-circle" />
    </WithTooltip>
  );

  return (
    <div>
      <input
        type="radio"
        name={name}
        id={actualId}
        checked={isChecked}
        value={actualValue}
        onChange={handleChange}
        disabled={isDisabled}
      />

      <label htmlFor={actualId}>{label} {toolTip}</label>

      {helpText && !helpTooltip && (<small>{helpText}</small>)}
    </div>
  );
};

UIRadio.defaultProps = {
  id: null,
  value: null,
  helpText: null,
  helpTooltip: false,
  isChecked: false,
  isDisabled: false,
};

UIRadio.propTypes = {
  id: PropTypes.string,
  helpText: PropTypes.string,
  helpTooltip: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default UIRadio;