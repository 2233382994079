import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from '../LoadingIndicator';
import DataTable, { Body } from '../DataTable';

class LoadingDataTable extends Component {
  cloneForLoading = child => (
    // Clone header and rows of table in order to maintain width / height when loading
    React.cloneElement(child)
  )

  render() {
    const { children, isLoading, ...tableProps } = this.props;
    const items = React.Children.map(children, this.cloneForLoading);
    const header = items[0];
    const rows = items[1].props.children;

    return (
      isLoading ? (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <LoadingIndicator isLarge minHeight={220} isLoading={isLoading} isFullHeight />
          </div>
          <table className="chargify-data-table">
            {header}
            <Body style={{ visibility: 'hidden' }}>
              {rows}
            </Body>
          </table>
        </div>
      ) : (
        <DataTable {...tableProps}>
          {children}
        </DataTable>
      )
    );
  }
}

LoadingDataTable.propTypes = {
  children: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingDataTable;
