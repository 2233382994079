import { StyleSheet, css } from 'aphrodite';

const logoAnimation = {
  '0%': { transform: 'rotate(-45deg) scale(0) translate(200px, 150px)' },
  '25%': { transform: 'rotate(-45deg) scale(0) translate(200px, 150px)' },
  '30%': { transform: 'rotate(0deg) scale(1) translate(200px, 150px)' },
  '100%': { transform: 'rotate(0deg) scale(1) translate(200px, 150px)' },
};

const indicatorAnimation = {
  '0%': { transform: 'rotate(0deg) translate(200px, 150px)' },
  '30%': { transform: 'rotate(730deg) translate(200px, 150px)' },
  '100%': { transform: 'rotate(730deg) translate(200px, 150px)' },
};

export { css };
export default StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    transformOrigin: '50% 50%',
    animationName: [logoAnimation],
    animationDuration: '1.8s',
    transitionTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  indicators: {
    transformOrigin: '50% 50%',
    animationName: [indicatorAnimation],
    animationDuration: '1.8s',
    transitionTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
});
