import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => (
  <thead className="chargify-data-table__header">
    {children}
  </thead>
);

Header.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Header;
