import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';

export const dateMoment = date =>
  date && moment(date, DATE_FORMAT).format(DATE_FORMAT);

const isValid = date =>
  !!(date && (dateMoment(date) !== 'Invalid date'));

export const areBothValid = (startDate, endDate) =>
  isValid(startDate) && isValid(endDate);
