import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AllocationChangeModal from './components/AllocationChangeModal';

function buildComponentParams(components) {
  return components.map((component) => (
    {
      component_id: component.componentId,
      generic_quantity_key: component.quantity,
      price_point_id: component.pricePointId,
    }
  ));
};

function OfferMigrationPreview({ initialPreview }) {
  const [preview, setPreview] = useState(initialPreview);
  const [submitting, setSubmitting] = useState(false);
  const [displayAllocationModal, setDisplayAllocationModal] = useState(false);
  const [components, setComponents] = useState([]);
  const [chargeBeingEdited, setChargeBeingEdited] = useState({});

  const goBack = () => window.location.assign(preview.backUrl);
  const goToSubscriptionsPath = () => window.location.assign(preview.successUrl); // TODO: Rename the field to subscriptionsUrl

  const handleChangeClick = (charge) => {
    setChargeBeingEdited(charge);
    setDisplayAllocationModal(true);
  };

  const setComponent = (allocation) => {
    const nextComponents = components.slice();
    const index = components.findIndex(c => c.componentId === allocation.componentId);

    if (index >= 0) {
      nextComponents[index] = allocation;
    } else {
      nextComponents.push(allocation);
    }

    setComponents(nextComponents);
  };

  const submitMigration = () => {
    if (submitting) { return; }

    setSubmitting(true);
    $.ajax({
      method: 'POST',
      context: this,
      data: JSON.stringify({
        components: buildComponentParams(components),
      }),
      dataType: 'json',
      contentType: 'application/json',
      url: preview.postUrl,
      success: goToSubscriptionsPath,
      error: error => {
        setSubmitting(false);
        goToSubscriptionsPath();
      }
    });
  };

  // Reload preview after component changes
  useEffect(() => {
    const url = `${window.location.pathname}.json`;

    $.ajax({
      context: this,
      data: {
        components: JSON.stringify(buildComponentParams(components)),
      },
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      success: result => {
        setPreview(result);
      },
      error: error => {
        goBack();
      }
    });
  }, [components]);

  const {
    amountDueInCents,
    charges,
    credits,
    displayExistingBalance,
    endDate,
    existingBalanceInCents,
    narratives,
    subtotalInCents,
  } = preview;

  return (
    <React.Fragment>
      <header className="interior">
        <h4>Details</h4>
      </header>

      <div className="section">
        <table id='migration-details' className="simple">
          <tbody>
            {charges.map((charge, i) => (
              <tr key={i}>
                <td className="kind">{charge.kind}</td>
                <td className="memo">
                  {charge.memo}
                  {(charge.editable || charge.pricePointSelectable) && (
                    <a role="button" onClick={() => handleChangeClick(charge)}> edit</a>
                  )}
                </td>
                <td className="amount">{charge.amount}</td>
              </tr>
            ))}

            {credits.map((credit, i) => (
              <tr key={i}>
                <td className="kind">Credit</td>
                <td className="memo">{credit.memo}</td>
                <td className="amount">{credit.amount}</td>
              </tr>
            ))}

            <tr>
              <td className="label-subtotals" colSpan="2">Subtotal</td>
              <td className="amount subtotal">{subtotalInCents}</td>
            </tr>

            {displayExistingBalance && (
              <tr>
                <td className="label-subtotals" colSpan="2">Existing Balance</td>
                <td className="amount existing_balance">{existingBalanceInCents}</td>
              </tr>
            )}

            <tr>
              <td className="label-totals" colSpan="2">Today's Total</td>
              <td className="amount amount-totals">{amountDueInCents}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="section inverse migration-further-details">
        <ul>
          {narratives.map((narrative, i) => (
            <li key={i} className="note-credit glyphicon glyphicon-bullhorn">
              {narrative}
            </li>
          ))}

          <li className="note-next-billing glyphicon glyphicon-repeat">
            Your subscription will renew again on {endDate}
          </li>
        </ul>
      </div>

      <div className="section migration-confirmation">
        <div className={`button ${submitting && 'disabled'}`} style={{ float: "right" }} onClick={submitMigration}>Confirm</div>
        <a role="button" tabIndex={0} onClick={goBack} className="cancel">Back</a>
      </div>

      {displayAllocationModal && (
        <AllocationChangeModal
          charge={chargeBeingEdited}
          setComponent={setComponent}
          toggleModal={setDisplayAllocationModal}
        />
      )}
    </React.Fragment>
  );
};

export default OfferMigrationPreview;

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-offer-migration-preview')

  if(node) {
    const data = JSON.parse(node.getAttribute('data-camelized-props'))

    ReactDOM.render(
      <OfferMigrationPreview {...data} />,
      node.appendChild(document.createElement('div')),
    )
  }
})
