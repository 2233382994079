import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Cell } from '../../shared/DataTable';

class InvoiceRow extends Component {
  linkToInvoice = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { invoice, onClick } = this.props;
    onClick(invoice.invoicePath);
  };

  consolidationDescription = () => {
    const { invoice } = this.props;
    switch (invoice.consolidationLevel) {
      case 'parent':
        return 'Consolidated Invoice';
      case 'child':
        return 'Consolidated Invoice Segment';
      default:
        return 'Normal Invoice';
    }
  };

  consolidationIconClass = () => {
    const { invoice } = this.props;
    switch (invoice.consolidationLevel) {
      case 'parent':
        return 'fal fa-file-plus';
      case 'child':
        return 'fal fa-file-minus';
      default:
        return 'fal fa-file';
    }
  };

  render() {
    const { invoice } = this.props;

    return (
      <Row id={`invoice_${invoice.number}`} onClick={this.linkToInvoice} isClickable>
        <Cell customClasses="number">
          <i
            className={this.consolidationIconClass()}
            title={this.consolidationDescription()}
            style={{ color: '#808080', marginRight: '10px', width: '7px' }}
          />
          {invoice.prefixedNumber}
        </Cell>
        <Cell customClasses="issued_at">{invoice.issueDate}</Cell>
        <Cell customClasses="due_date">{invoice.dueDate}</Cell>
        <Cell customClasses="status">{invoice.status}</Cell>
        <Cell customClasses="total_amount" alignRight isDisabled={invoice.status === 'VOIDED'}>
          {invoice.totalAmount}
        </Cell>
        <Cell customClasses="amount_due" alignRight>{invoice.amountDue}</Cell>
        <Cell alignRight style={{ width: '130px' }}>
          View <i className="fa fa-angle-right" />
        </Cell>
      </Row>
    );
  }
}

InvoiceRow.propTypes = {
  invoice: PropTypes.shape({
    amountDue: PropTypes.string,
    consolidationLevel: PropTypes.string,
    issueDate: PropTypes.string,
    dueDate: PropTypes.string,
    prefixedNumber: PropTypes.string,
    subscriberName: PropTypes.string,
    subscriptionId: PropTypes.number,
    status: PropTypes.string,
    totalAmount: PropTypes.string,
    uid: PropTypes.string,
    invoice_path: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default InvoiceRow;
