import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UIFormRow } from '../studio';

const StyledInfoSection = styled.div`
 display: block;
 border: 1px solid var(--cy-border-color);
 border-radius: 4px;
 padding: 20px;
 width: 100%;
`;

const StyledUlInfoSection = styled.ul`
 padding-left: 15px;
`;

const InfoSection = ({
  isCancelOnSpecificDate,
  isCancelImmediately,
  formattedScheduledCancellationAt,
  subscriptionState,
  isSchedulingCancellationAvailable }) => {
  const currentCollectionTiming = isCancelOnSpecificDate ? 'Cancel on Specific Date' : 'Cancel at End of Period';

  const scheduledCancellationMessage = (<li>
    Cancellation takes effect around {formattedScheduledCancellationAt} if you press &quot;
    {currentCollectionTiming}&quot;
  </li>);

  const cancellationTimeMessage = () => {
    if (isCancelImmediately) {
      return (<li>
        Cancellation takes effect immediately if you press &quot;Cancel Immediately&quot;
      </li>);
    }
    return scheduledCancellationMessage;
  };

  return (
    <UIFormRow>
      <StyledInfoSection>
        <h5>What&apos;s going to happen?</h5>

        <StyledUlInfoSection className="bulleted" >
          <li>
            The credit card will no longer be charged
            after cancellation takes effect
          </li>
          {cancellationTimeMessage()}
          <li>
            No refunds or prorations will be made
          </li>
          { isSchedulingCancellationAvailable && <li>
            This subscription is <strong>{subscriptionState}</strong> so
            the cancellation cannot be scheduled.
          </li>
          }
        </StyledUlInfoSection>
      </StyledInfoSection>
    </UIFormRow>
  );
};

InfoSection.propTypes = {
  isCancelOnSpecificDate: PropTypes.bool.isRequired,
  isCancelImmediately: PropTypes.bool.isRequired,
  formattedScheduledCancellationAt: PropTypes.string,
  subscriptionState: PropTypes.string.isRequired,
  isSchedulingCancellationAvailable: PropTypes.bool.isRequired,
};

export default InfoSection;